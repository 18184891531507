const advanceEditorConfig = {
  toolbar: {
    items: [
      'heading', '|', 'fontColor', 'highlight', '|', 
      'bold', 'italic', 'underline', 'strikethrough', '|', 'alignment', '|', 'numberedList', 
      'bulletedList', '|', 'indent', 'outdent', '|', 'link', 'insertTable', '|', 
      'pageBreak', '|', 'undo', 'redo'
    ],
  },
};

const simpleEditorConfig = {
  toolbar: {
    items: [
      'fontColor', 'highlight', '|', 
      'bold', 'italic', 'underline', 'strikethrough', '|', 'alignment', '|', 'numberedList', 
      'bulletedList', '|', 'indent', 'outdent', '|', 'undo', 'redo'
    ],
    
  },
};

export {
  advanceEditorConfig,
  simpleEditorConfig
}
