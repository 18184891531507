import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":0}},[_c(VToolbar,{attrs:{"tile":"","flat":""}},[_c(VToolbarTitle,[_c('h1',{staticClass:"title font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$tc('blog.TAGS', 2))+" ")])]),_c(VSpacer),_c(VIcon,{on:{"click":_vm.closePanelTool}},[_vm._v("mdi-close")])],1),_c(VCardText,[_c('p',{staticClass:"body-1"},[_vm._v("Agrega tags que describen de qué trata tu entrada. Los lectores pueden usarlas para encontrar otras entradas con contenido similar.")]),_c(VRow,[_c(VCol,[_c('h1',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$tc('blog.ADDTAG', 1))+" "),_c(VIcon,[_vm._v("mdi-information-outline")])],1)]),_c(VCol,{attrs:{"cols":"2"}},[_c('h1',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.getTagsLength)+"/30")])])],1),_c(VCombobox,{attrs:{"outlined":"","items":_vm.items,"search-input":_vm.search,"hide-selected":"","hint":_vm.$t('blog.MAXTAGS', { value: 30 }),"multiple":"","persistent-hint":"","small-chips":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" No results matching \""),_c('strong',[_vm._v(_vm._s(_vm.search))]),_vm._v("\". Press "),_c('kbd',[_vm._v("enter")]),_vm._v(" to create a new one ")])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c(VChip,_vm._b({attrs:{"outlined":"","input-value":selected}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item)+" ")]),_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" mdi-close-circle ")])],1)]}}]),model:{value:(_vm.editedItem.tags),callback:function ($$v) {_vm.$set(_vm.editedItem, "tags", $$v)},expression:"editedItem.tags"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }