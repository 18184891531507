<template>
  <v-card :elevation="0">
    <v-toolbar tile flat>
      <v-toolbar-title>
        <h1 class="title font-weight-regular">
          {{ $tc('blog.CATEGORY', 2) }} 
        </h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon @click="closePanelTool">mdi-close</v-icon>
    </v-toolbar>
    <v-card-text>
      <p class="body-1">Agrega categorías para que los lectores puedan navegar por las páginas de tu blog por temas, por ejemplo, comida, estilo de vida y viajes.</p>
    </v-card-text>
    <v-card-actions>
      <v-btn text outlined color="primary">
        <v-icon left small>mdi-plus</v-icon> 
        {{ $tc('blog.CREATECATEGORY', 1) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "post-category-options",
  props: {
    editedItem: Object,
  },
  data() {
    return {
    }
  },
  methods: {
    closePanelTool () {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
  .v-card {
    width: 100%;
  }
</style>
