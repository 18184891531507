<template>
  <div class="pt-16 text-center">
    <v-card v-for="item in menu" :key="item.n" color="drawerbackground" class="py-4" :elevation="0" tile>
      <v-btn @click="selectPanelTool(item.name)" icon outlined :color="isActiveButton === item.name  ? 'primary' : ''">
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
      <h1 :class="isActiveButton === item.name ? 'primary--text' : ''" class="caption">{{ $tc(item.translate, item.position) }}</h1>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "post-menu-options",
  props: {
    openToolPanel: Boolean,
    currentPanel: String,
    menu: Array
  },
  data() {
    return {
    }
  },
  computed: {
    isActiveButton () {
      return this.openToolPanel ? this.currentPanel : null
    }
  },
  methods: {
    selectPanelTool(value) {
      this.$emit('selectpanel', value)
    },
  },
}
</script>

<style>

</style>
