import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":0}},[_c(VToolbar,{attrs:{"tile":"","flat":""}},[_c(VToolbarTitle,[_c('h1',{staticClass:"title font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$tc('blog.CATEGORY', 2))+" ")])]),_c(VSpacer),_c(VIcon,{on:{"click":_vm.closePanelTool}},[_vm._v("mdi-close")])],1),_c(VCardText,[_c('p',{staticClass:"body-1"},[_vm._v("Agrega categorías para que los lectores puedan navegar por las páginas de tu blog por temas, por ejemplo, comida, estilo de vida y viajes.")])]),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","outlined":"","color":"primary"}},[_c(VIcon,{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc('blog.CREATECATEGORY', 1))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }