<template>
  <v-container class="custom-container">
    <v-row class="px-4 align-center">
      <v-btn v-if="isChange" @click="saveChanges" class="d-flex d-sm-none" block outlined color="primary">
        <v-icon left>mdi-alert-circle-outline</v-icon>
        {{ $t('blog.UNPUBLISHED') }}
      </v-btn>
      <v-list-item  class="px-0" two-line dense :class="isMobileScreen ? 'panel-width-mobile' : 'panel-width'">
        <v-list-item-avatar class="primary 
justify-center" size="42">
          <v-avatar color="primary lighten-1" size="41">
            <v-icon color="white">mdi-account</v-icon>
            <!-- <v-img src="@/assets/person.jpg" alt="Jhon Travel"></v-img> -->
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="subtitle-1 font-weight-regular">
            {{ editedItem.autor.name.first }} {{ editedItem.autor.name.last }}
          </v-list-item-title>
          <v-list-item-subtitle>1 Min. de lectura • Caracteres: 1350</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-spacer></v-spacer>
      <v-btn v-if="isChange" @click="saveChanges" class="d-none d-sm-flex" outlined color="primary">
        <v-icon left>mdi-alert-circle-outline</v-icon>
        {{ $t('blog.UNPUBLISHED') }}
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          :change="toggleChangeData()"
          class="headline"
          clearable
          counter="100"
          :placeholder="$t('blog.ADDTITLE')"
          v-model="editedItem.name"
          :label="$t('TITLE')">
        </v-text-field>
      </v-col>

      <v-col v-if="editedItem.airports != null" cols="12">
        <v-select
          v-model="editedItem.airports"
          :items="airportList"
          label="Airports"
          multiple
          chips
          item-text="name"
          item-value="iata"
          hint="Select multiple options"
          persistent-hint>
        </v-select>
      </v-col>

      <v-col cols="12">
        <ckeditor 
          :change="toggleChangeData()"
          :editor="editor" 
          v-model="editedItem.description" 
          :config="editorConfig">
        </ckeditor>
      </v-col>

      <v-col cols="12">
        <v-img 
          v-if="editedItem.image !== null"
          max-height="540"
          :src="require(`@/assets/${editedItem.image}`)" 
          :alt="editedItem.slug">
        </v-img>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { advanceEditorConfig } from '../plugins/ckeditorhelper.js';
import '@ckeditor/ckeditor5-build-classic/build/translations/es';
import AirporList from '../assets/airviva/airviva-airports.json'

export default {
  name: "body-editor",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ClassicEditor
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    isMobileScreen: {
      type: Boolean,
      required: true
    },
    isChange: {
      type: Boolean,
      required: true
    }
  },
  created () {
    this.setValues()
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: advanceEditorConfig,
      language: "es",
      airportList: [],
    }
  },
  computed: {
    isChangeData () {
      return this.isChange;
    }
  },
  methods: {
    setValues () {
      if (AirporList) {
        const arrayFailed = Object.entries(AirporList).map((arr) => (
          arr[1]
        ));
        this.airportList = arrayFailed;
        // arrayFailed.forEach((element) => {
        //   this.airportList.push(element);
        // });
      }
    },
    toggleChangeData () {
      this.$emit('toggle');
    },
    saveChanges () {
      this.$emit('save');
    }
  }
}
</script>

<style scoped>

.custom-container {
  max-width: 1100px;
  min-width: 360px;
  width: 100%;
  padding-bottom: 86px;
}

.panel-width {
  max-width:50%;
}

.panel-width-mobile {
  max-width:100%;
}
</style>
