function fortmatDate (date, locale) {
  if (!date) return null
  const [year, month, day] = date.split('-')
  if (locale === 'en')
    return `${month}/${day}/${year}`
  else
    return `${day}/${month}/${year}`
}

export default {
  fortmatDate
}
