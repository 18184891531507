<template>
  <v-card :elevation="0">
    <v-toolbar tile flat>
      <v-toolbar-title>
        <h1 class="title font-weight-regular">
          {{ $tc('blog.TAGS', 2) }} 
        </h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon @click="closePanelTool">mdi-close</v-icon>
    </v-toolbar>
    <v-card-text>
      <p class="body-1">Agrega tags que describen de qué trata tu entrada. Los lectores pueden usarlas para encontrar otras entradas con contenido similar.</p>
      <v-row>
        <v-col>
          <h1 class="subtitle-1">
          {{ $tc('blog.ADDTAG', 1) }} <v-icon>mdi-information-outline</v-icon></h1> 
        </v-col>
        <v-col cols="2">
          <h1 class="subtitle-1">
          {{ getTagsLength }}/30</h1> 
        </v-col>
      </v-row>
      <v-combobox
        outlined
        v-model="editedItem.tags"
        :items="items"
        :search-input.sync="search"
        hide-selected
        :hint="$t('blog.MAXTAGS', { value: 30 })"
        multiple
        persistent-hint
        small-chips>
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No results matching "<strong>{{ search }}</strong>". Press <kbd>enter</kbd> to create a new one
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip
            outlined
            v-bind="attrs"
            :input-value="selected"
            >
            <span class="pr-2">
              {{ item }}
            </span>
            <v-icon
              small
              @click="parent.selectItem(item)">
              mdi-close-circle
            </v-icon>
          </v-chip>
        </template>
      </v-combobox>

    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "post-tags-options",
  props: {
    editedItem: Object,
  },
  data() {
    return {
      items: ['Gaming', 'Programming', 'Vue', 'Vuetify'],
      search: null,
    }
  },
  watch: {
    model (val) {
      if (val.length > 30) {
        this.$nextTick(() => this.editedItem.tags.pop())
      }
    },
  },
  computed: {
    getTagsLength () {
      return this.editedItem.tags.length
    }
  },
  methods: {
    closePanelTool () {
      this.$emit('close')
    },
    isEmptyTags(value) {
      return value.tags.length > 0
    }
  },
}
</script>

<style scoped>
  .v-card {
    width: 100% !important;
  }
</style>
